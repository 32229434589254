import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { Card } from "@blueprintjs/core";
import { graphql } from "gatsby";
import SEO from "../components/seo";

export const BasicPage = ({ data }) => {
    let meta: SeoObject = {
        ...data.mdx.frontmatter,
        title: `${data.mdx.frontmatter.title} - jracollins.com`,
    };
    return (
        <Layout>
            <SEO meta={meta} />
            <div className="full-bleed wrapper row-gap">
                <Card className="content">
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </Card>
            </div>
        </Layout>
    );
};

export default BasicPage;

export const mdxLayoutTemplateQuery = graphql`
    query PageBySlug($slug: String!) {
        mdx(frontmatter: { slug: { eq: $slug } }) {
            body
            tableOfContents
            frontmatter {
                layout
                slug
                title
                date(formatString: "DD MMMM YYYY")
                relativeDate: date(fromNow: true)
                isoDate: date(formatString: "YYYY-MM-DDTHH:mm:ssZ")
                tags
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;
